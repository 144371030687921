@import url("https://fonts.googleapis.com/css2?family=Electrolize&display=swap");

* {
 margin: 0;
 padding: 0;
 box-sizing: border-box;
}

body {
 font-size: 14px;
 font-weight: 400;
 font-style: normal;
 color: var(--black);
 background-color: var(--gray);
 font-family: "Electrolize", sans-serif;
 overflow-x: hidden;
}

li {
 list-style: none;
 cursor: pointer;
}

.container {
 width: 1440px;
 margin: 0 auto;
 height: 100%;
}

@media screen and (max-width: 1400px) {
 .container {
  width: 90%;
  margin: 0 auto;
 }
}

.zoom-in {
 animation: zoomInEffect 10s ease-in-out infinite;
}

.zoom-out {
 animation: zoomOutEffect 10s ease-in-out infinite;
}

@keyframes zoomInEffect {
 0%,
 100% {
  transform: scale(1);
 }
 50% {
  transform: scale(1.1);
 }
}

@keyframes zoomOutEffect {
 0%,
 100% {
  transform: scale(1.1);
 }
 50% {
  transform: scale(1);
 }
}
