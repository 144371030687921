.mobile-side-menu {
 position: fixed;
 top: 0;
 left: 0;
 width: 100%;
 height: 100%;
 z-index: 101;
 transition: 0.3s;
 visibility: hidden;
 opacity: 0;
 backdrop-filter: blur(3px);
}

.mobile-side-menu .content {
 background-color: #fff;
 width: 300px;
 height: 100%;
 position: fixed;
 transform: translateX(-100%);
 transition: 0.4s;
 overflow: hidden;
 left: 0;
}

.mobile-side-menu .content ul.menu {
 padding: 30px;
 padding-bottom: 60px;
 overflow-y: auto;
 height: calc(100% - 70px);
}

.mobile-side-menu .content ul.menu li.seperator {
 position: relative;
 padding-top: 25px;
}

.mobile-side-menu .content ul.menu li.seperator a {
 padding-top: 20px;
}

.mobile-side-menu .content ul.menu li.seperator::before {
 content: "";
 width: calc(100% + 60px);
 height: 1px;
 background-color: #dddddd;
 margin-left: -30px;
 margin-right: -30px;
 display: block;
}

.mobile-side-menu .content ul.menu li:first-child a {
 padding-top: 0;
}

.mobile-side-menu .content ul.menu li:last-child a {
 padding-bottom: 0;
}

.mobile-side-menu .content ul.menu a {
 display: flex;
 align-items: center;
 padding: 10px 0;
}

.mobile-side-menu .content ul.menu a img {
 margin-right: 15px;
 width: 26px;
}

.mobile-side-menu .heading {
 padding: 19px 15px 19px 25px;
 background-color: #1e4ea2;
}

.mobile-side-menu .heading h5 {
 font-size: 14px;
 font-weight: 500;
 color: #fff;
}

.mobile-side-menu::after {
 content: "";
 position: fixed;
 top: 0;
 left: 0;
 width: 100%;
 height: 100%;
 background-color: #000;
 opacity: 0;
 visibility: hidden;
 z-index: -1;
 transition: 0.3s;
}

.mobile-side-menu.active {
 opacity: 1;
 visibility: visible;
}

.mobile-side-menu.active .content {
 transform: translateX(0);
}

.mobile-side-menu.active::after {
 visibility: visible;
 opacity: 0.3;
}
